import React from 'react';
import { Footer, Header } from '../../components';
import { Col, Img, MainContainer, Row } from 'ui/layout';
import styled from 'styled-components';
import ill2 from 'assets/partners/ill2.png';
import { BREAKPOINTS, COLORS } from '../../utils';
import PDF1 from './ОБЩИЕ_УСЛОВИЯ_аренды_техники.pdf'
import PDF2 from './Общие_условия_автотранспортных_услуг_внутриобъектные.pdf';
import PDF3 from './ОБЩИЕ_УСЛОВИЯ_аренды_самосвалов.pdf';
import PDF4 from './ОБЩИЕ_УСЛОВИЯ_ОКАЗАНИЯ_УСЛУГ_СПЕЦТЕХНИКОЙ.pdf';

export const PartnersPage = () => {
  return (
    <div>
      <Header active={'partners'} />

      <Wrapper>
        <MainContainer>
          <Row alignCenter>
            <TextCol width={'35%'} xlWidth={'42%'} lgWidth={'100%'}>
              <H1>
                Стать нашим
                <br />
                подрядчиком
                <br />и поставщиком
              </H1>
              <Row marginTop={20} direction='column' gap>
                <DownloadLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href={PDF3}
                >
                  ОБЩИЕ УСЛОВИЯ аренды caмосвалов
                </DownloadLink>

                <DownloadLink
                 target='_blank'
                  rel='noopener noreferrer'
                  href={PDF4}
                >
                  ОБЩИЕ УСЛОВИЯ аренды техники
                </DownloadLink>

                <DownloadLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href={PDF2}
                >
                  Общие условия автотранспортных услуг внутриобъектные
                </DownloadLink>

                <DownloadLink
                  href={PDF1}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  ОБЩИЕ УСЛОВИЯ ОКАЗАНИЯ УСЛУГ СПЕЦТЕХНИКОЙ
                </DownloadLink>
              </Row>
              <FeedbackContainer>
                Обратная связь:{' '}
                <PostLink href='mailto:info@allgk.ru'>info@allgk.ru</PostLink>
              </FeedbackContainer>
            </TextCol>
            <Col width={'65%'} xlWidth={'58%'} lgHidden paddingLeft={3}>
              <Ill2 src={ill2} alt={''} />
            </Col>
          </Row>
        </MainContainer>

        <Ill2Tablet src={ill2} alt={''} />
      </Wrapper>

      <Footer />
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 137px;
  padding-bottom: 138px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-top: 132px;
    padding-bottom: 0;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 55px;
    padding-bottom: 27px;
  }

  :before {
    content: '';
    position: absolute;
    left: -76px;
    top: 0;
    height: 89px;
    width: 100px;
    background: #0c67b1;
    transform: skew(-30deg);
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    :before {
      height: 26px;
      left: -90px;
      top: 32px;
    }
  }
`;
const H1 = styled.h1`
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 38px;
    line-height: 46px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const TextCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-left: 21px;
  }
`;
const FeedbackContainer = styled.div`
  margin-top: 53px;
  min-height: 53px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 19px;
    max-width: 289px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 205px;
    margin-top: 24px;
  }
`;
const PostLink = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: ${COLORS.primary1};
  :hover {
    opacity: 0.6;
  }
`;
const Ill2 = styled(Img)`
  width: 100%;
  height: 525px;
  object-fit: cover;
`;
const Ill2Tablet = styled(Img)`
  display: none;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
    margin-top: 136px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 26px;
  }
`;

const DownloadLink = styled.a`
  margin-bottom: 12px;
  text-decoration: none;
  font-weight: 600;
  color: ${COLORS.primary1};
  text-transform: lowercase;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;